<template>
	<router-view />
</template>

<script>
//import basicPage from "@/basicPage";
//import scorePage from "@/scorePage";

export default {
	components: {},
	setup() {
		return {};
	},
};
</script>

<style lang="scss">
html {
	background-size: cover;
	background-image: url(./assets/background-dark.svg);
	background-position: center bottom;
	background-repeat: no-repeat;
	background-attachment: fixed;
	min-height: 100%;
}

@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&display=swap");

#app {
	min-height: 100%;
	padding-bottom: 3rem;
	font-family: "Lato", sans-serif !important;
	/*font-family: Avenir, Helvetica, Arial, sans-serif;*/
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-align: left;
	color: #2c3e50;
	margin-top: 0px;
	position: relative;

	.view-container {
		max-width: 1170px;
		margin: auto;
		overflow: hidden;
	}

	a {
		text-decoration: none;
		color: inherit;
	}

	.p-card-content p a {
		text-decoration: none;
	}

	.section a {
		text-decoration: underline;
	}

	.p-button {
		color: #fff;
	}

	h1,
	h2,
	h3,
	h4,
	h5,
	.p-card-title {
		color: #444;
		font-family: "Lato", sans-serif !important;
		font-weight: 900 !important;
	}

	h1 {
		font-size: 2.25rem;
	}

	h2 {
		font-size: 1.5rem;
	}

	.p-message .p-message-text {
		padding-bottom: 0.25rem;
	}

	.p-button {
		text-decoration: none;
	}

	.text-muted {
		color: #777;
		font-style: italic;
	}

	.fade,
	.p-card {
		animation: fadeIn 0.35s;
		-webkit-animation: fadeIn 0.35s;
		-moz-animation: fadeIn 0.35s;
		-o-animation: fadeIn 0.35s;
		-ms-animation: fadeIn 0.35s;
	}

	@keyframes fadeIn {
		0% {
			opacity: 0;
		}

		100% {
			opacity: 1;
		}
	}

	@-moz-keyframes fadeIn {
		0% {
			opacity: 0;
		}

		100% {
			opacity: 1;
		}
	}

	@-webkit-keyframes fadeIn {
		0% {
			opacity: 0;
		}

		100% {
			opacity: 1;
		}
	}

	@-o-keyframes fadeIn {
		0% {
			opacity: 0;
		}

		100% {
			opacity: 1;
		}
	}

	@-ms-keyframes fadeIn {
		0% {
			opacity: 0;
		}

		100% {
			opacity: 1;
		}
	}
}

//hide recaptcha flag
html body .grecaptcha-badge {
	display: none;
	height: 0;
}

.p-dialog .p-dialog-header {
	h3 {
		margin: 0px;
	}
}
</style>
