<template>
  <Card class="">
    <template #content>
      <div class="grid">
        <div class="col-12  md:col-6">
          <h1 class="mb-1">{{ titlePrefix }} Scoreboard</h1>
          <div
            class="mb-3 px-2 py-1"
            style="background: #e4eaf0;border-radius:3px;overflow:hidden"
          >
            Url: https://gmscoreboard.com/{{ viewUrl }}
          </div>
        </div>
        <div
          class="col-12 md:col-6 flex text-right align-items-center justify-items-center justify-content-end"
        >
          <router-link
            class="p-button p-component p-button-secondary"
            to="/account/dashboard"
            >Back</router-link
          >
        </div>
      </div>

      <form
        @submit.prevent="handleSubmit(!v$.$invalid)"
        class="scoreboard-form p-fluid"
      >
        <div class="fluid formgrid grid">
          <div class="field col-12 md:col-4">
            <div class="">
              <label
                for="name"
                :class="{ 'p-error': v$.name.$invalid && submitted }"
                >Name*</label
              >
              <InputText
                id="name"
                v-model="v$.name.$model"
                :class="{ 'p-invalid': v$.name.$invalid && submitted }"
              />
            </div>
            <small
              v-if="
                (v$.name.$invalid && submitted) || v$.name.$pending.$response
              "
              class="p-error"
              >{{ v$.name.required.$message.replace("Value", "Name") }}</small
            >
          </div>
          <div class="field col-12 md:col-4">
            <div class="">
              <label
                for="order"
                :class="{ 'p-error': v$.rowOrder.$invalid && submitted }"
                >Row Order</label
              >
              <Dropdown
                id="order"
                v-model="v$.rowOrder.$model"
                :class="{ 'p-invalid': v$.rowOrder.$invalid && submitted }"
                :options="orderOptions"
                optionLabel="name"
                optionValue="name"
              />
            </div>
          </div>
          <div class="field col-12 md:col-4 pl-3 pr-3">
            <label
              for="rowLimit"
              :class="{ 'p-error': v$.rowLimit.$invalid && submitted }"
              >Number of Rows: {{ v$.rowLimit.$model }}</label
            >
            <Slider
              id="rowLimit"
              class="mt-2"
              v-model="v$.rowLimit.$model"
              :min="1"
              :max="50"
            />
          </div>

          <div class="field col-12 md:col-4">
            <label for="platformUrlSteam"
              >Steam Url <br /><span class="text-muted"
                >https://store.steampowered.com/</span
              ></label
            >
            <InputText id="platformUrlSteam" v-model="state.platformUrlSteam" />
          </div>

          <div class="field col-12 md:col-4">
            <label for="platformUrlAndroid"
              >Play Store Url <br /><span class="text-muted"
                >http://play.google.com/</span
              ></label
            >
            <InputText
              id="platformUrlAndroid"
              v-model="state.platformUrlAndroid"
            />
          </div>

          <div class="field col-12 md:col-4">
            <label for="platformUrlIos"
              >App Store Url <br /><span class="text-muted"
                >http://appstore.com/</span
              ></label
            >
            <InputText id="platformUrlIos" v-model="state.platformUrlIos" />
          </div>

          <div class="field col-12">
            <label for="description" class="">Description</label>
            <Textarea v-model="state.description" rows="5" style="width:100%" />
          </div>

          <div class="col-12 md:col-6">
            <div class="upload-con">
              <div class="grid">
                <div
                  class="col-fixed field flex align-items-center"
                  style="width:auto"
                >
                  <div class="image-frame">
                    <img class="icon-demo" :src="iconSrc" alt="" />
                  </div>
                </div>
                <div class="col flex align-items-center">
                  <div class="field">
                    <label for="description" class=""
                      >Upload Icon (100kb Max Size)</label
                    >
                    <FileUpload
                      ref="IconElem"
                      name="Icon"
                      :auto="true"
                      @uploader="IconUploader"
                      :customUpload="true"
                      mode="basic"
                      :maxFileSize="100000"
                      :fileLimit="1"
                      accept="image/png, image/gif, image/jpeg"
                    >
                    </FileUpload>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-12 md:col-6">
            <div class="upload-con ">
              <div class="grid">
                <div
                  class="col-fixed field flex align-items-center"
                  style="width:auto"
                >
                  <div
                    class="image-frame flex align-items-center"
                    style="width:254px;height:150px"
                  >
                    <img
                      class="banner-demo"
                      :src="bannerSrc"
                      alt=""
                      v-if="bannerSrc"
                    />
                  </div>
                </div>
                <div class="col flex align-items-center">
                  <div class="field">
                    <label for="description" class=""
                      >Upload Banner <br />(250kb Max size, 950x200px)</label
                    >
                    <FileUpload
                      ref="bannerElem"
                      name="banner"
                      :auto="true"
                      @uploader="BannerUploader"
                      :customUpload="true"
                      mode="basic"
                      :maxFileSize="250000"
                      :fileLimit="1"
                      accept="image/png, image/gif, image/jpeg"
                    >
                    </FileUpload>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12">
            <Divider />
          </div>

          <div class="col flex justify-content-between" style="width:100%">
            <Button
              type="submit"
              class="save-button p-mt-2"
              :disabled="submitDisabled"
            >
              <div class="text-center" style="width:100%">
                <i
                  v-show="submitDisabled"
                  class="pi pi-spin pi-spinner mr-1"
                  style="fontSize: 1rem"
                ></i>
                Save
              </div>
            </Button>
            <div v-show="titlePrefix == 'Edit'">
            <Button
              style="width:auto"
              @click="openClearDialog"
              class="p-button p-component p-button-secondary mr-1"
              >Clear Scores</Button>
            <Button
              style="width:auto"
              @click="openDeleteDialog"
              v-tooltip.top="'Delete Scoreboard'"
              class="p-button p-component p-button-secondary"
              ><i class="pi pi-trash" style="fontSize: 1.35em"></i
            ></Button>
            </div>
          </div>
        </div>
      </form>
    </template>
  </Card>
  <Dialog v-model:visible="showDeleteDialog" position="top" :modal="true">
    <template #header>
      <h3>Delete this Scoreboard.</h3>
    </template>

    Are you sure you wish to perminantly delete this scoreboard and all of it's
    scores?

    <template #footer>
      <Button
        @click="closeDialog"
        label="Cancel"
        icon="pi pi-times"
        class="p-button p-component p-button-secondary"
      />
      <Button
        @click="confirmDelete"
        label="Confirm Delete"
        icon="pi pi-check"
        class="p-button p-component p-button-primary"
        autofocus
      />
    </template>
  </Dialog>
  <Dialog v-model:visible="showClearDialog" position="top" :modal="true">
    <template #header>
      <h3>Clear all scores from this Scoreboard.</h3>
    </template>

    Are you sure you wish to perminantly remove all scores from this scoreboard?

    <template #footer>
      <Button
        @click="closeDialog"
        label="Cancel"
        icon="pi pi-times"
        class="p-button p-component p-button-secondary"
      />
      <Button
        @click="confirmClear"
        label="Confirm Clear"
        icon="pi pi-check"
        class="p-button p-component p-button-primary"
        autofocus
      />
    </template>
  </Dialog>
</template>

<script>
import { reactive, ref, onMounted, watch } from "vue";

import { required } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";

import router from "@/router";
import emitter from "@/emitter";
import api from "@/services/ApiService";


export default {
  head: {
    title: {
      inner: "Edit Scoreboard",
    },
  },
  components: {},
  props: {
    id: String,
  },
  setup(props) {
    emitter.emit("require-login");
    const orderOptions = [
      {
        name: "High to Low",
      },
      {
        name: "Low to High",
      },
    ];

    const iconSrc = ref(
      "https://upload.gmscoreboard.com/uploads/default-gm-icon.png"
    );
    const bannerSrc = ref("");
    const viewUrl = ref("");
    const bannerElem = ref(null);
    const IconElem = ref(null);
    const titlePrefix = ref("Add");

    const state = reactive({
      id: props.id,
      name: "",
      platformUrlSteam: "",
      platformUrlAndroid: "",
      platformUrlIos: "",
      description: "",
      iconUrl: "",
      bannerUrl: "",
      rowLimit: 25,
      rowOrder: orderOptions[0].name,
    });

    const rules = {
      name: {
        required,
      },
      rowLimit: {
        required,
      },
      rowOrder: {
        required,
      },
    };

    const submitted = ref(false);
    const submitDisabled = ref(true);
    const v$ = useVuelidate(rules, state);

    //get edit data if applicable.
    onMounted(async () => {
      if (props.id > 0) {
        let res = await api.post("/get-scoreboard", {
          id: props.id,
        });
        if (res.success && res.scoreboards.length) {
          titlePrefix.value = "Edit";
          console.log("scoreboard", res.scoreboards[0]);

          let res_data = res.scoreboards[0];
          state.name = res_data.name;
          state.description = res_data.description;
          state.iconUrl = res_data.iconUrl;
          state.bannerUrl = res_data.bannerUrl;
          state.rowLimit = res_data.rowLimit;
          state.rowOrder = res_data.rowOrder;
          state.platformUrlSteam = res_data.platformUrlSteam;
          state.platformUrlAndroid = res_data.platformUrlAndroid;
          state.platformUrlIos = res_data.platformUrlIos;

          iconSrc.value =
            "https://upload.gmscoreboard.com/uploads/" + res_data.iconUrl;
          bannerSrc.value =
            "https://upload.gmscoreboard.com/uploads/" + res_data.bannerUrl;
        }
      }
      submitDisabled.value = false;
    });

    async function IconUploader(event) {
      submitDisabled.value = true;
      let file = event.files[0];
      let res = await api.upload(file);
      if (res.success) {
        state.iconUrl = res.filename;
        iconSrc.value = "https://upload.gmscoreboard.com/temp/" + res.filename;
        console.log("upload res", res);
      }
      IconElem.value.clear();
      submitDisabled.value = false;
    }

    async function BannerUploader(event) {
      submitDisabled.value = true;
      let file = event.files[0];

      let res = await api.upload(file);
      if (res.success) {
        state.bannerUrl = res.filename;
        bannerSrc.value =
          "https://upload.gmscoreboard.com/temp/" + res.filename;
        //console.log("upload res",res);
      }
      bannerElem.value.clear();
      submitDisabled.value = false;
    }

    //watch urls to adjust the value
    watch(state, (newState) => {
      state.platformUrlSteam = newState.platformUrlSteam.replace(
        "https://store.steampowered.com/",
        ""
      );
      state.platformUrlAndroid = newState.platformUrlAndroid.replace(
        "http://play.google.com/",
        ""
      );
      state.platformUrlIos = newState.platformUrlIos.replace(
        "http://appstore.com/",
        ""
      );
    });

    /*
                async function cancelUpload(name){
                    if (name == "icon"){
                        state.iconUrl = '';
                        iconSrc.value = 'https://upload.gmscoreboard.com/uploads/default-gm-icon.png';
                    }
                    if (name == "banner"){
                        state.bannerUrl = '';
                        bannerSrc.value = '/#';
                    }
                    return false;
                }
        */

    async function handleSubmit(isFormValid) {
      console.log("submit state", state);

      submitted.value = true;

      if (!isFormValid) {
        return;
      }

      submitDisabled.value = true;
      //create account request
      let send_data = JSON.parse(JSON.stringify(state));
      console.log("send data", send_data);
      let res = await api.post("save-edit-scoreboard", send_data);
      if (res.success) {
        router.push("/account/dashboard");
      }
      submitDisabled.value = false;
    }

    //formats the url
    watch(state, (newState) => {
      viewUrl.value = newState.name
        .replace(/\s+/g, "-")
        .replace(/[^0-9a-zA-Z-]/gi, "");
    });

    const showDeleteDialog = ref(false);
    const openDeleteDialog = () => {
        console.log("open delete");
        showDeleteDialog.value = true;
    }

    async function confirmDelete(){
        submitDisabled.value = true;
        let send_data = JSON.parse(JSON.stringify(state));
        console.log("send data", send_data);
        let res = await api.post("delete-scoreboard", {
          id: props.id,
        });
        if (res.success) {
        router.push("/account/dashboard");
        }
        submitDisabled.value = false;
        closeDialog();
    }

    const showClearDialog = ref(false);
    const openClearDialog = () => {
        console.log("open clear");
        showClearDialog.value = true;
    }

    async function confirmClear(){
        submitDisabled.value = true;
        let send_data = JSON.parse(JSON.stringify(state));
        console.log("send data", send_data);
        await api.post("clear-scoreboard", {
          id: props.id,
        });
        submitDisabled.value = false;
        closeDialog();
    }

    const closeDialog = () => {
        showDeleteDialog.value = false;
        showClearDialog.value = false;
    }

    return {
      state,
      v$,
      handleSubmit,
      submitted,
      orderOptions,
      submitDisabled,
      iconSrc,
      bannerSrc,
      IconUploader,
      BannerUploader,
      viewUrl,
      bannerElem,
      IconElem,
      titlePrefix,
      showDeleteDialog,
      openDeleteDialog,
      confirmDelete,
      closeDialog,
      showClearDialog,
      openClearDialog,
      confirmClear,
    };
  },
};
</script>

<style lang="scss">
#app {
  .upload-con {
    top: 10px;
    position: relative;

    .grid {
      height: 100%;
    }
  }

  .save-button {
    width: 120px;
  }

  .image-frame {
    background: #ced4da;
    height: 150px;
    width: 100%;
    border-radius: 4px;
  }

  .icon-demo {
    width: 90px;
    height: 90px;
    background: #ced4da;
    margin: 2rem;
    border-radius: 3px;
  }

  .banner-demo {
    width: 190px;
    height: 40px;
    background: #ced4da;
    margin: 2rem;
    border-radius: 3px;
  }
}
</style>
