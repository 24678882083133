<template>
<form @submit.prevent="handleSubmit(!v$.$invalid)" class="registration-form p-fluid">
    <div class="field">
        <div class="p-float-label p-input-icon-right">
            <i class="pi pi-envelope" />
            <InputText id="email" v-model="v$.email.$model" :class="{'p-invalid':v$.email.$invalid && submitted}" aria-describedby="email-error" />
            <label for="email" :class="{'p-error':v$.email.$invalid && submitted}">Email*</label>
        </div>
        <span v-if="v$.email.$error && submitted">
            <span id="email-error" v-for="(error, index) of v$.email.$errors" :key="index">
                <small class="p-error">{{error.$message}}</small>
            </span>
        </span>
        <small v-else-if="(v$.email.$invalid && submitted) || v$.email.$pending.$response" class="p-error">{{v$.email.required.$message.replace('Value', 'Email')}}</small>
    </div>
    <div class="field">
        <div class="p-float-label">
            <InputText id="password" type="password" v-model="v$.password.$model" :class="{'p-invalid':v$.password.$invalid && submitted}" aria-describedby="password-error" />
            <label for="password" :class="{'p-error':v$.password.$invalid && submitted}">Password*</label>
        </div>
        <small v-if="(v$.password.$invalid && submitted) || v$.password.$pending.$response" class="p-error">{{v$.password.required.$message.replace('Value', 'Password')}}</small>
    </div>
    <Button type="submit" label="Submit" class="p-mt-2" />
    <div class="text-muted text-center mt-2 w-full text-600">
        <router-link to="/password-reset/">Forgot Password</router-link>
    </div>
</form>
</template>

<script>
import {
    reactive,
    ref,
    onMounted
} from 'vue';

import {
    email,
    required
} from "@vuelidate/validators";
import {
    useVuelidate
} from "@vuelidate/core";

import api from "@/services/ApiService";
import router from "@/router";
//import emitter from '@/emitter'

import {
    useGtag
} from "vue-gtag-next";

export default {
    setup() {
        const state = reactive({
            email: '',
            password: '',
        });

        const rules = {
            email: {
                required,
                email
            },
            password: {
                required
            }
        };

        const submitted = ref(false);
        const v$ = useVuelidate(rules, state);

        const {
            query
        } = useGtag()

        onMounted(() => {})

        async function handleSubmit(isFormValid) {
            console.log("submit");
            submitted.value = true;

            if (!isFormValid) {
                return;
            }

            let send_data = state;
            let res = await api.post('login', send_data);
            if (res.success) {
                let token_data = {
                    auth_email: res.email,
                    auth_token: res.token
                };
                localStorage.setItem('GMSLoginToken', JSON.stringify(token_data));
                //emitter.emit('check-login-session');

                query('event', 'login', {
                    'event_category': 'engagement',
                    'event_label': 'login',
                    'value': 1
                });
                router.push('/account/dashboard/');
            }
        }

        return {
            state,
            v$,
            handleSubmit,
            submitted,
        }
    }
}
</script>

<style lang="scss">
.registration-form {
    max-width: 450px;
    width: 100%;
    margin: 0 auto;

    .field {
        margin-bottom: 1.5rem;
    }

    .field>label {
        margin-bottom: .25em;
    }
}
</style>
