import config from '@/config'
import axios from 'axios'
import emitter from '@/emitter'

const basic_config = {
    baseURL: config.server_url,
    timeout: 3000,
    responseType: 'json',
    method: 'get',
    headers: { Authorization: "" }
};



let show_messages = true;

async function get(url, data) {
    try {
        if (data == undefined) { data = {}; }
        basic_config.method = 'get';
        api_set_auth_token();
        basic_config.params = data;

        const response = await axios.get(url, basic_config);
        console.log('Get Request', url, basic_config, response);
        if (response != undefined && response.status == 200) {
            return api_handle_response(response);
        } else {
            throw new Error('Response not ok.');
        }
    } catch (error) {
        return api_handle_error(error);
    }
}

async function post(url, data) {
    try {
        basic_config.method = 'post';
        api_set_auth_token();
        const response = await axios.post(url, data, basic_config);
        console.log('Post Request', url, data, response.status);
        if (response != undefined && response.status == 200) {
            return api_handle_response(response);
        } else {
            throw new Error('Response not ok.');
        }
    } catch (error) {
        return api_handle_error(error);
    }
}

async function upload(data) {
    try {
        let formData = new FormData();
        formData.append('file', data);

        basic_config.method = 'post';
        basic_config.headers = { 'Content-Type': 'multipart/form-data' }
        const response = await axios.post('https://upload.gmscoreboard.com/', formData, basic_config);
        console.log('Upload Request', data, response);
        if (response != undefined && response.status == 200) {
            return api_handle_response(response);
        } else {
            throw new Error('Response not ok.');
        }
    } catch (error) {
        return api_handle_error(error);
    }
}




export default { get, post, upload }

//setup authorization if applicable.
function api_set_auth_token() {
    let auth_data = JSON.parse(localStorage.getItem('GMSLoginToken'));
    if (auth_data != null && auth_data.auth_email != undefined && auth_data.auth_token != undefined) {
        basic_config.headers = { Authorization: auth_data.auth_token };
    }
}

function api_handle_response(response) {

    //show server message.
    if (response.data.status != undefined && response.data.message != undefined && response.data.message.length > 0) {
        console.log('response message', response.data.message);
        emitter.emit('alert-message', {
            severity: response.data.status,
            content: response.data.message
        });
    }

    response.data.success = false;
    if (response.data.status == 'success') {
        response.data.success = true;
    }

    return response.data;
}

function api_handle_error(error) {

    if (show_messages) {
        emitter.emit('alert-message', {
            severity: 'warn',
            content: 'Error communicating with the server.'
        });
    }

    console.log(error);
    return { success: false };


}