<template>
    <div class="footer-container">
        <div class=" view-container">
            <p class="p-text-center">
                &copy; Theory Georgiou {{ year }} |
                <a href="mailto:gmscoreboard@gmail.com">Contact</a>
            </p>
        </div>
    </div>
</template>

<script>
export default {
    components: {},
    setup() {
        const year = new Date().getFullYear();

        return { year };
    },
};
</script>

<style>
.footer-container {
    position: absolute;
    bottom: 0px;
    width: 100%;
}

.footer-container p {
    font-family: "Lato", sans-serif !important;
    font-weight: 700;
    color: #c7d8de;
    text-align: center;
}
</style>
