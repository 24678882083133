<template>
<Card class="dashboard-page">
    <template #content>

        <div class="grid">
            <div class="col-12 md:col-6 ">
                <h1>Dashboard</h1>
            </div>
            <div  class="col-12 md:col-6 flex text-right align-items-center justify-items-center justify-content-end">

                <span v-show="maxScoreboards > 0">{{rows ? rows.length : 0 }} of {{maxScoreboards}} Free Scoreboards</span>
                <Button class="ml-3" @click="gotoEditScoreboard()" :disabled='addDisabled'>Add Scoreboard</Button>

            </div>
        </div>

        <DataTable :value="rows" stripedRows dataKey="id" responsiveLayout="scroll">
            <Column field="name" header="Scoreboard" style="min-width:400px">
                <template #body="slotProps">
                    <img :src="slotProps.data.iconUrl" alt="" class="icon-image" style="vertical-align: middle;margin-right:1rem" />
                    <span class="image-text">{{slotProps.data.name}}</span>
                </template>
            </Column>
            <Column field="rowLimit" header="Max Rows"></Column>
            <Column field="rowOrder" header="Order"></Column>
            <Column field="tagId" header="API TagId">
            </Column>
            <Column field="edit" header="">
                <template #body="slotProps">
                    <div class="col flex align-items-center justify-content-end">
                        <Button v-tooltip.top="'View Scores'" class="p-button p-component p-button-secondary ml-2" @click="gotoViewScoreboard(slotProps.data.viewUrl)"><i class="pi pi-eye" style="fontSize: 1.25em"></i></Button>
                        <Button v-tooltip.top="'Edit Details'" class="p-button p-component p-button-secondary ml-2" @click="gotoEditScoreboard(slotProps.data.id)"><i class="pi pi-pencil" style="fontSize: 1.25em"></i></Button>
                        <!--<div class="text-right" style=""><i class="pi pi-chevron-right"></i></div>-->
                    </div>
                </template>
            </Column>
        </DataTable>

    </template>
</Card>
</template>

<script>
import {
    ref,
    onMounted,
} from 'vue'
import emitter from '@/emitter'
import api from '@/services/ApiService'
import router from "@/router";

export default {
    head: {
        title: {
            inner: 'Dashboard'
        }
    },
    components: {},
    setup() {
        emitter.emit('require-login');

        const rows = ref([]);
        const maxScoreboards = ref(0);
        const selectedRow = ref();
        const addDisabled = ref(true);

        onMounted(async () => {

            let res = await api.post('/get-scoreboard', {
                all: true
            });
            if (res.success) {

                res.scoreboards.forEach(function (item) {
                    if (item.iconUrl.length <= 0) {
                        item.iconUrl = 'default-gm-icon.png';
                    }
                    item.iconUrl = 'https://upload.gmscoreboard.com/uploads/' + item.iconUrl;
                });

                rows.value = res.scoreboards;
                maxScoreboards.value = res.account[0].maxScoreboards;
            }


            if (rows.value.length < maxScoreboards.value) {
                addDisabled.value = false;
            }

        })



        //makes entire row selectable.
        /*
        watch(selectedRow, (selection) => {
            if (selectedRow.value != undefined) {
                router.push('/account/edit-scoreboard/' + selection.id);
                console.log('selected', selection.id);
                selectedRow.value = undefined;
            }
        })
        */

        function gotoEditScoreboard(id) {
            if (id == undefined) {
                id = '';
            }
            router.push('/account/edit-scoreboard/' + id);
        }

        function gotoViewScoreboard(id) {
            if (id != undefined) {
                window.open('/' + id, '_blank');
            }

        }

        return {
            rows,
            selectedRow,
            gotoEditScoreboard,
            gotoViewScoreboard,
            maxScoreboards,
            addDisabled
        };
    },
};
</script>

<style lang="scss">
#app {
    .dashboard-page {
        .p-datatable table {}

        .p-datatable .p-datatable-tbody>tr.p-highlight {
            background: #e9ecef;
            color: #212529;
            font-weight: bold;
        }

        .icon-image {
            max-width: 40px;
            max-height: 40px;
            width: 40px;
            height: 40px;
        }

        .p-datatable thead th:nth-child(1),
        .p-datatable tbody td:nth-child(1),
        .p-datatable tfoot td:nth-child(1) {
            min-width: 350px;
            width: 350px;
        }

        .p-datatable thead th:last-child,
        .p-datatable tbody td:last-child,
        .p-datatable tfoot td:last-child {}
    }
}
</style>
