<template>
	<Card class="">
		<template #content>
			<div class="section col-12 md:col-12">
				<h1>Changelog</h1>
				<p>
					Here we will keep track of major updates made to
					gmscoreboard.com
				</p>

				<h2>Guide Update</h2>
				<p>12/5/2021</p>
				<p>
					Added images and more information into the
					Documentation/Guide.
				</p>

				<Divider />

				<h2>Scoreboard clearing and deleting.</h2>
				<p>11/7/2021</p>

				<p>Added feature to clear all scores from scoreboards.</p>
				<p>Added feature to perminantly delete a scoreboard.</p>

				<Divider />

				<h2>GM Scoreboard Rank and Platform Links!</h2>
				<p>9/28/2021</p>

				<p>
					Added platform links for Steam, Play Store and App store.
					Which will display a corresponding button on scoreboard
					pages.
				</p>
				<p>
					Added "Rank" data returned with the set-score API endpoint.
				</p>
				<p>
					The example scoreboard used in the documentation examples
					will now regularly automatically clear iteself periodically.
				</p>

				<Divider />

				<h2>GM Scoreboard Complete Rework!</h2>
				<p>9/19/2021</p>
				<p>
					Welcome to the new gmscoreboard.com. I've comepletely
					rebuilt gmscoreboard with the intent to provide a more
					reliable services and better experience for everyone.
				</p>

				<h3>Accessing your Account on the new Website</h3>
				<p>
					To access your account you will first need to reset your
					password before logging in. Please go to the login page and
					hit "Forgot Password".
				</p>

				<h3>API Changes</h3>
				<p>
					Big changes have been made here.Please consult the
					documentation page and update your games accordingly. I've
					also kept the old endpoint and format as best I can for
					legeacy reasons but will discontinue this option in a few
					months. I will update everyone when that time approaches.
				</p>
				<p>
					A change that effects the new and legacy api's is that
					duplicates scores are no longer preserved. You will get an
					error if a duplicate is submitted. Another change is api
					calls to get scores are limited to only returning the top 3.
					I'll have more information on when and how this will be
					increased again in the future.
				</p>

				<h3>Website and Interface Changes</h3>
				<p>
					The biggest change is a much cleaner look and better mobile
					responsiveness
				</p>
				<p>
					You can now manage multiple Scoreboards from one account.
					Currently limited to 2 per account. I'll have more
					inforamtion on increasing this in the near future
				</p>

				<h3>Support</h3>
				<p>
					I've done my best to preserve all data and important
					features durring the transition to the new website. But it's
					a big change so if there are any issues or you find a bug
					please let me know.
				</p>
				<p>
					<a href="mailto:info@gmscoreboard.com"
						>info@gmscoreboard.com</a
					>
				</p>
			</div>
			<div class="col-12 md:col-4"></div>
		</template>
	</Card>
</template>

<script>
export default {
	head: {
		title: {
			inner: "GM Scoreboard Change Log",
		},
		meta: [
			{
				name: "description",
				content:
					"Here we will keep track of major updates made to gmscoreboard.com",
				id: "desc",
			},
		],
	},
	components: {},
	setup() {
		return {};
	},
};
</script>

<style lang="scss">
.code {
	background: #e9ecef;
	padding: 0.5rem 1rem;
	border-radius: 2px;
}
</style>
