//handle account login session changes via emitters.
//store account_session data and save it locally as needed.

import { reactive } from "vue"
import router from '@/router'
import api from "@/services/ApiService";
import emitter from '@/emitter'

export default function () {


    const login_session = reactive({
        checked: false,
        login: false,
        send_data: {}
    });


    //check for login session
    async function checkLoginSession() {
        console.log('checkLoginSession', login_session)
        login_session.login = false;


        if (localStorage.getItem('GMSLoginToken') !== null) {
            let send_data = JSON.parse(localStorage.getItem('GMSLoginToken'));
            if (send_data.auth_email != undefined && send_data.auth_token != undefined) {
                let res = await api.post('validate-session', send_data);
                login_session.checked = true;
                if (res.success) {
                    login_session.send_data = send_data;
                    login_session.login = true;
                    console.log('login session found', res);
                } else {
                    emitter.emit('logout');
                    console.log('login session not found', res);
                }
            } else {
                emitter.emit('logout');
                console.log("No local login session found.");
            }
        }

        emitter.emit('update-nav', login_session.login);
    }


    emitter.on('require-login', async function () {

        if (!login_session.checked) {
            await checkLoginSession();
        }

        console.log('require-login', login_session);
        if (login_session.checked && !login_session.login) {
            router.push('/login');
        }
    });

    emitter.on('logout', async function () {
        localStorage.removeItem('GMSLoginToken');
        login_session.checked = false;
        login_session.login = false;
        await checkLoginSession();
        router.push('/login');
    });

    return {
        login_session,
        checkLoginSession
    }
}