<template>
<Card class="card">
    <template #content>

        <div class="grid">
            <div class="col-12 md:col-4 lg:col-4 mx-auto">
                <h1>Login</h1>
                <LoginForm />
            </div>
        </div>
    </template>
</Card>
</template>

<script>
import LoginForm from '@/views/login/LoginForm'
import emitter from '@/emitter'

export default {
    head: {
        title: {
            inner: 'Login'
        }
    },
    components: {
        LoginForm
    },
    props: {
        logout: String
    },
    setup(props) {

        if (props.logout != undefined && props.logout == 'logout') {
            emitter.emit('logout');
        }

        return {}
    }
}
</script>

<style lang="scss">


.card {
    ul {
        padding: 0
    }

    ul li {
        list-style: none;
        margin-bottom: 1rem;
    }

    ul li i {
        margin-right: 1.5rem;
    }
}
</style>
