<template>
<Card class="card">
    <template #content>

        <div class="grid">
            <div class="col-12 md:col-4 lg:col-4 mx-auto">
                <h1>Reset Password</h1>
                <PasswordResetForm v-if="showResetForm" />
                <NewPasswordForm :token="token" v-if="showNewForm" />
            </div>
        </div>
    </template>
</Card>
</template>

<script>
import {
    ref,
    onMounted
} from 'vue';

import PasswordResetForm from '@/views/login/PasswordResetForm'
import NewPasswordForm from '@/views/login/NewPasswordForm'
///import api from "@/services/ApiService";
//import router from "@/router";
//import emitter from '@/emitter'

export default {
    head: {
        title: {
            inner: 'Password Reset'
        }
    },
    components: {
        PasswordResetForm,
        NewPasswordForm
    },
    props: {
        token: String
    },
    setup(props) {

        const showResetForm = ref(false);
        const showNewForm = ref(false);
        //const token = ref(props.token);

        //handle reset token.
        onMounted(async () => {
            if (props.token != undefined && props.token.length > 0) {
                showNewForm.value = true;
            }else{
                showResetForm.value = true;
            }
        });

        return {
            showResetForm,
            showNewForm,
        }
    }
}
</script>

<style lang="scss">
.card {
    ul {
        padding: 0
    }

    ul li {
        list-style: none;
        margin-bottom: 1rem;
    }

    ul li i {
        margin-right: 1.5rem;
    }
}
</style>
