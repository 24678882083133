<template>
	<div class="view-container main-menu-container">
		<Menubar :model="items" class="main-menu">
			<template #start>
				<router-link to="/">
					<img
						class="logo-img p-mr-2"
						alt="logo"
						src="@/assets/logo.png"
						width="312"
						height="41"
					/>
				</router-link>
			</template>
		</Menubar>
	</div>
</template>

<script>
import { ref } from "vue";
import Menubar from "primevue/menubar";
import emitter from "@/emitter";

export default {
	components: {
		Menubar,
	},
	setup() {
		const items = ref([]);

		//change nav based on login status.
		emitter.on("update-nav", async function (login) {
			items.value = [
				{
					label: "Game Scores",
					to: "/game-scores",
				},
				{
					label: "How to Guide",
					to: "/documentation",
				},
			];

			if (!login) {
				items.value.push({
					label: "Register",
					to: "/registration",
					class: "nav-item-right",
				});

				items.value.push({
					label: "Login",
					to: "/login",
				});
			} else {
				items.value.push({
					label: "Dashboard",
					to: "/account/dashboard",
					class: "nav-item-right",
				});

				items.value.push({
					label: "Logout",
					to: "/login/logout",
				});
			}
		});

		emitter.emit("update-nav", false);

		return {
			items,
		};
	},
};
</script>

<style lang="scss">
#app .main-menu-container{
	overflow: unset;
}
.main-menu {
	margin-top: 1rem;
	margin-bottom:1rem;

	a {
		text-decoration: none;
		color: #fff;
	}
}

.p-menubar {
	background: #1f2425 !important;
}

.p-menuitem-text {
	font-family: "Lato", sans-serif !important;
	font-weight: 700;
	color: #c7d8de !important;
}

@media only screen and (max-width: 960px) {
	#app{
		.main-menu {
			margin-top: 0rem;
			margin-bottom:0.5rem;
		}
		
		.p-menubar .p-menubar-root-list {
			color: #c7d8de !important;
			background: #1f2425 !important;
			top: calc(100% - 5px);
			border-top: 0px;
		}

		.logo-img {
			width: 80%;
			max-width: 312px;
			max-height: 41px;
		}
	}
}

.p-menubar .p-menubar-button {
	margin-left: auto;
}

/*remove highlight on focus nav item*/
.p-menubar .p-menuitem-link:focus,
.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:focus {
	box-shadow: none !important;
}

.p-menubar-root-list {
	width: 100%;
	display: flex;
}

.nav-item-right {
	margin-left: auto;
}

.p-menubar-button {
	color: #c7d8de !important;

	i {
		font-size: 1.5rem;
	}
}
</style>
