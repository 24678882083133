import { createApp } from "vue";
import App from "./App.vue";
import PrimeVue from "primevue/config";
import router from "./router";
import lodash from "lodash";
import VueHead from "@morr/vue3-head";
import VueGtag from "vue-gtag-next";
import { VueReCaptcha } from "vue-recaptcha-v3";
import ConfirmationService from "primevue/confirmationservice";
//import VueMeta from "vue-meta";

console.log("ENV", process.env.NODE_ENV, process.env);

//load analytics and turn off debug console for production
if (process.env.NODE_ENV == "production") {
    //console.log = () => { };
}

const app = createApp(App);
app.use(PrimeVue);
app.use(router);
app.use(VueHead);
app.use(VueReCaptcha, { siteKey: "6LdEdIQcAAAAALxEevbmItgTKjCzsv1CSCu4U87J" });

app.use(VueGtag, {
    property: [
        {
            id: "UA-9839117-15",
            params: {
                send_page_view: false,
            },
        },
        {
            id: "AW-1000302406",
            params: {
                send_page_view: false,
            },
        },
    ],
    useDebugger: false,
});

//setup primevue
import "primeicons/primeicons.css";
import "primevue/resources/themes/bootstrap4-light-blue/theme.css";
import "primevue/resources/primevue.min.css";
import "primeflex/primeflex.min.css";
app.use(ConfirmationService);

//automatically import the components in the component folder.
const requireComponent = require.context(
    "./components",
    true,
    /Base[A-Z]\w+\.(vue|js)$/
);
auto_import_components(requireComponent);

import primevueloader from "./primevueloader";
primevueloader(app);

//run app
app.mount("#app");

function auto_import_components(requireComponent) {
    requireComponent.keys().forEach((fileName) => {
        const componentConfig = requireComponent(fileName);
        const componentName = lodash.upperFirst(
            lodash.camelCase(
                fileName
                    .split("/")
                    .pop()
                    .replace(/\.\w+$/, "")
            )
        );

        app.component(
            componentName,
            componentConfig.default || componentConfig
        );
    });
}

//vue-meta script for google ad sense
/*
head.script.push({
    async: true,
    src: "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js",
    dataAdClient: "ca-pub-yourId",
});
*/

/*
<script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-4661175919385534"
     crossorigin="anonymous"></script>
     */
