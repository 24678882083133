<template>
    <Card class="fade">
        <template #content>
            <div class="surface-0 text-700 text-center mb-4">
                <div class="text-900 font-bold text-5xl mb-3">
                    Game Maker Scoreboard
                </div>
                <h1 class="text-700 text-2xl mb-5">
                    Free online score tracking for your game project.
                </h1>
                <Button
                    @click="goto"
                    label="Create a Scoreboard"
                    icon=""
                    class="font-bold px-5 py-3 p-button-raised p-button-rounded white-space-nowrap"
                ></Button>
            </div>
            <Divider />
            <div class="grid">
                <div
                    class="section col-12 md:col-10 md:col-offset-1 text-center"
                >
                    <p>
                        GM Scoreboard is a free online service for tracking and
                        displaying high-scores in your Game Maker Studio,
                        Unity3d or any game project. The service provides an API
                        so you can easily integrate it with your game, and also
                        has a web interface that displays the current top 50
                        scores of all time.
                    </p>

                    <p>
                        Your players can use GM Scoreboard to show off their
                        latest gaming accomplishment on a global leaderboard by
                        sharing their achievements with friends through social
                        media.
                    </p>

                    <p>
                        Our platform intigrates with any game on any platform
                        including Android, iOS, Windows PC, Switch, PS5 and
                        Xbox. Customize your scoreboard page with a banner and
                        background color. View your
                        <a href="/documentation">single step guide</a> to get
                        started.
                    </p>
                    <!--
                        <p>GM Scoreboard provides free online score tracking for your Game Maker Studio or Unity3d game project. View your scoreboards or leaderboards at your custom gmscoreboard url.
                            Player score data is stored permanently online in the cloud and can be accessed from anywhere through our easy to use API.</p>
                        <p>Our platform intigrates with any game on any platform with access to the internet including Android, iOS, Windows PC, Switch, PS5 and Xbox.
                            Each GM Scoreboard account can list the top(or bottom) 50 player scores of your games or game levels.
                            Game scores are updated and retrieved easily from within the gaming client. Customize your scoreboard page with a banner and background color.</p>
                        <p>Follow our single step guide to start tracking player scores with your own online scoreboard or leaderboard in less than 5 minutes.</p>
-->
                </div>
                <div class="col-12 md:col-12">
                    <scoreDisplay num="3" />
                </div>

                <div class="col-12 md:col-12 text-center">
                    <h2>Improving Game Maker Scoreboard</h2>
                    <p>
                        We are always looking to improve our services. Please
                        contact us at
                        <a href="mailto:gmscoreboard@gmail.com"
                            >gmscoreboard@gmail.com</a
                        >
                        with feature requests and bug reports.
                    </p>
                </div>
            </div>
        </template>
    </Card>
</template>

<script>
import router from "@/router";
import scoreDisplay from "@/components/ScoreDisplay";

export default {
    head: {
        title: {
            inner: "Online score tracking for your game",
        },
        meta: [
            {
                name: "description",
                content:
                    "GM Scoreboard provides free online score tracking for your arcade style games with scoreboards and leader boards hosted right here on this website. Player score data is stored permanently online in the cloud and can be accessed from anywhere through our easy to use API.",
                id: "desc",
            },
        ],
    },
    components: {
        scoreDisplay,
    },
    setup() {
        function goto() {
            router.push("registration");
        }

        return {
            goto,
        };
    },
};
</script>

<style lang="scss"></style>
