<template>
<MainNav />
<div class="view-container fade">
    <Message v-for="msg of alertMessages" :severity="msg.severity" :key="msg.content">{{msg.content}}</Message>
    <div class="grid">
        <div class="col-12">
            <router-view />
        </div>
    </div>
</div>
<Footer />
</template>

<script>
import {
    ref, 
    onMounted
} from 'vue';
import emitter from '@/emitter'
import Message from 'primevue/message';

import MainNav from "@/components/MainNav";
import Footer from "@/components/Footer";

//import api from "@/services/ApiService";
import LoginSession from "@/services/LoginSession";
//import router from "@/router";

export default {
    components: {
        MainNav,
        Footer,
        Message
    },
    setup() {

        const {
            login_session,
            checkLoginSession
        } = LoginSession();

        //initial login check.
        onMounted(async () => {
            await checkLoginSession();
        });

        //handle and show alert messages, will blink between message updates.
        const alertMessages = ref([]);
        emitter.on('alert-message', async function (msg) {
            if (alertMessages.value.length > 0) {
                alertMessages.value = [];
                setTimeout(function () {
                    emitter.emit('alert-message', msg);
                }, 1);
            } else {
                alertMessages.value = [msg];
            }
        });

        return {
            alertMessages,
            login_session
        };
    },
};
</script>

<style lang="scss">
</style>
