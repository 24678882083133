<template>
<teleport to="head">
    <title>Registration</title>
</teleport>

<Card class="card registration-page">
    <template #content>

        <div class="grid mb-4">
            <div class="col-12 md:col-4 lg:col-4 ">
                <div class="mx-auto">
                    <h2>Registration</h2>
                    <RegForm />
                </div>
            </div>
            <div class="col-12 md:col-8 lg:col-8 flex align-items-center justify-content-center">
                <div class="mx-auto" style="width:auto; max-width:600px">
                    <h2>Free Gm Scoreboard</h2>
                    <ul>
                        <li><i class="pi pi-check"></i>Create upto 2 free scoreboards featuring upto 50 player scores each.</li>
                        <li><i class="pi pi-check"></i>Use our API to update and store score data for your scoreboard.</li>
                        <li><i class="pi pi-check"></i>Customize the look of your scoreboard page at your unique url (ad supported).</li>
                    </ul>

                    <h2 class="mb-1">Premium Gm Scoreboard</h2>
                    <p class="mb-2">More robust features are in the works. Please <a href="mailto:info@gmscoreboard.com">contact us</a> with service suggestions and feedback.</p>
                    <!--
                <p class="mb-2">To get Premium first register your account, you will have the opportunity to upgrade from your dashboard.</p>
                <ul>
                    <li><i class="pi pi-check"></i>All features in the Free version.</li>
                    <li><i class="pi pi-check"></i>Create and manage upto 10 scoreboard.</li>
                    <li><i class="pi pi-check"></i>Use our API to also retreive score data for use within your game or website.</li>
                    <li><i class="pi pi-check"></i>Additional customization features for your scoreboard page.</li>
                </ul>
                -->

                </div>
            </div>
        </div>
    </template>
</Card>
</template>

<script>
import RegForm from '@/views/registration/RegistrationForm'

export default {
    head: {
        title: {
            inner: 'Registration'
        }
    },
    components: {
        RegForm
    },
    setup() {
        return {}
    }
}
</script>

<style lang="scss" scoped>
#app {
    .registration-page {
        a {
            text-decoration: underline;
        }

        .terms {
            background: #eee !important;
        }

        .card {
            ul {
                padding: 0
            }

            ul li {
                list-style: none;
                margin-bottom: 1rem;
            }

            ul li i {
                margin-right: 1.5rem;
            }
        }
    }
}




</style>
