<template>
<Card class="card text-center">
    <template #content>
        <h1>Email Verification</h1>
    </template>
</Card>
</template>

<script>
import api from "@/services/ApiService";
import router from "@/router";

import {
    useGtag
} from "vue-gtag-next";

export default {
    head: {
        title: {
            inner: 'Verify Email'
        }
    },
    props: {
        email_token: String
    },
    setup(props) {

        const {
            query
        } = useGtag()

        //process the form when submitted using an api endpoint.
        async function submitForm() {
            let send_data = {
                email_token: props.email_token
            }
            
            //google ads only counts once per user. want to ensure it fires so it goes before api call.
            query('event', 'conversion', {
                'send_to': 'AW-1000302406/idynCKfI_oIDEMbO_dwD'
            });

            let res = await api.post('verify-email', send_data);
            if (res.success) {
                query('event', 'email-verified', {
                    'event_category': 'engagement',
                    'event_label': 'email-verified',
                    'value': 1
                });

                router.push('/login');
            }
        }

        submitForm();
        return {}
    }
}
</script>

<style lang="scss">

</style>
