<template>
	<div class="view-container score-page fade" style="max-width: 950px">
		<div
			v-if="pageData.bannerSrc"
			class="banner-con flex justify-content-center banner-show"
		>
			<img
				class="banner-image"
				:src="pageData.bannerSrc"
				:alt="pageData.name"
			/>
		</div>

		<div class="details">
			<h1>{{ pageData.name }}</h1>
			<p>{{ pageData.description }}</p>

			<div
				class="
					link-display-con
					md:m-2
					grid
					flex
					align-items-center
					justify-content-center
				"
			>
				<div class="col-12 md:col-4" v-if="pageData.platformUrlSteam">
					<a :href="pageData.platformUrlSteam">
						<img
							src="@/assets/steam-download-logo.png"
							width="191"
							height="64"
						/>
					</a>
				</div>
				<div class="col-12 md:col-4" v-if="pageData.platformUrlAndroid">
					<a :href="pageData.platformUrlAndroid">
						<img
							src="@/assets/android-download-logo.png"
							width="191"
							height="64"
						/>
					</a>
				</div>
				<div class="col-12 md:col-4" v-if="pageData.platformUrlIos">
					<a :href="pageData.platformUrlIos">
						<img
							src="@/assets/apple-download-logo.png"
							width="191"
							height="64"
						/>
					</a>
				</div>
			</div>
		</div>
		<div
			v-show="hasScores == false"
			class="details"
			style="border: 1px solid #dee2e6"
		>
			<h2>No scores yet.</h2>
		</div>

		<DataTable
			v-show="hasScores"
			:value="rows"
			stripedRows
			showGridlines
			dataKey="id"
			responsiveLayout="scroll"
		>
			<Column field="place">
				<template #body="slotProps">
					{{ slotProps.data.place }}
				</template>
			</Column>
			<Column field="player">
				<template #body="slotProps">
					{{ slotProps.data.player }}
				</template>
			</Column>
			<Column field="score">
				<template #body="slotProps" class="score">
					{{ slotProps.data.score }}
				</template>
			</Column>
		</DataTable>
	</div>
	<div class="footer-container">
		<div class="view-container">
			<p class="p-text-center">
				<a href="https://gmscoreboard.com"
					>powered by gmscoreboard.com</a
				>
			</p>
		</div>
	</div>
</template>

<script>
import { reactive, ref, onMounted } from "vue";

import api from "@/services/ApiService";
import router from "@/router";

export default {
	head() {
		return {
			title: "GM Scoreboard",
			meta: [
				{
					name: "description",
					content: "Showing Player scores the for the video game.",
					id: "desc",
				},
			],
			script: [
				{
					type: "text/javascript",
					src: "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-4661175919385534",
					async: true,
					body: false,
					crossorigin: "anonymous",
				},
			],
		};
	},
	components: {},
	props: {
		scoreboard: String,
	},
	setup(props) {
		//console.log("props", props.scoreboard);

		const hasScores = ref(null);
		const rows = ref([]);
		const pageData = reactive({});

		onMounted(async () => {
			if (props.scoreboard != "") {
				let res = await api.get("/api/get-scores/", {
					url: props.scoreboard,
					meta: "ASH42376jk56g", //secret key for getting detailed scoreboard data and all scores.
				});
				if (res.success && res.scoreboard) {
					pageData.bannerSrc =
						res.scoreboard.bannerUrl.length > 0
							? `https://upload.gmscoreboard.com/uploads/${res.scoreboard.bannerUrl}`
							: null;
					pageData.name = res.scoreboard.name;
					pageData.description = res.scoreboard.description;

					if (res.scoreboard.platformUrlSteam.length) {
						pageData.platformUrlSteam =
							"https://store.steampowered.com/" +
							res.scoreboard.platformUrlSteam;
					}

					if (res.scoreboard.platformUrlAndroid.length) {
						pageData.platformUrlAndroid =
							"https://play.google.com/store/apps/details?id=" +
							res.scoreboard.platformUrlAndroid;
					}

					if (res.scoreboard.platformUrlIos.length) {
						pageData.platformUrlIos =
							"http://appstore.com/" +
							res.scoreboard.platformUrlIos;
					}

					if (res.scores.length) {
						hasScores.value = true;

						res.scores.forEach(function (item, index) {
							item.place = "#" + (index + 1);
							item.score = item.score
								.toString()
								.replace(/\B(?=(\d{3})+(?!\d))/g, ","); //add commas.
						});
					} else {
						hasScores.value = false;
					}

					rows.value = res.scores;
				} else {
					router.push({ name: "Error404" });
				}
			}
		});

		return {
			rows,
			pageData,
			hasScores,
		};
	},
};
</script>

<style lang="scss">
#app {
	.score-page {
		.p-datatable tbody td {
			font-size: 1.5em;
			font-weight: bold;
			text-align: center;
			overflow: hidden;
		}

		@media screen and (min-width: 641px) {
			.p-datatable thead th:nth-child(1),
			.p-datatable tbody td:nth-child(1),
			.p-datatable tbody td:nth-child(1) > div,
			.p-datatable tfoot td:nth-child(1),
			.p-datatable thead th:nth-child(3),
			.p-datatable tbody td:nth-child(3),
			.p-datatable tbody td:nth-child(3) > div,
			.p-datatable tfoot td:nth-child(3) {
				max-width: 250px;
				width: 250px;
			}
		}

		.p-datatable tbody td:nth-child(2) {
		}

		.p-datatable table {
			background: #fff;
		}

		.p-datatable-thead {
			display: none;
			height: 0;
		}

		.banner-con {
			width: 100%;
			overflow: hidden;
			min-height: 200px;
			max-height: 200px;
			height: 200px;
		}

		.banner-image {
			width: 100%;
			height: auto;
		}

		.details {
			background: #fff;
			color: #111;
			text-align: center;
			padding: 2rem 2rem;

			h1 {
				font-size: 2em;
			}
		}

		@media screen and (max-width: 640px) {
			.banner-con {
				min-height: 100px;
				max-height: 100px;
				height: 100px;
			}

			.details {
				padding: 1rem 1rem;
			}

			.p-datatable tbody td {
				font-size: 0.72em;
			}
		}
	}
}
</style>
