<template>
    <Card class="fade">
        <template #content>
            <div class="section col-12 md:col-12">
                <h1>Get Started Guide & Documentation</h1>
                <p>
                    This page serves as a quick setup guide and documentation.
                    It requires some knowdlege of rest api and http requests. If
                    you require game engine specific tutorial see below.
                </p>
                <ul>
                    <li>
                        <a
                            href="https://forum.yoyogames.com/index.php?threads/easy-online-highscores.89533/#post-537288"
                            target="_blank"
                            >Game Maker Studio specific tutorial</a
                        >
                    </li>
                    <li>
                        <router-link to="/changelog">changelog</router-link>
                    </li>
                </ul>

                <h2>Set up a Scoreboard</h2>
                <p>
                    Login and you will be taken to the dashboard page. Here your
                    scorebaords will be listed. You can view their public page
                    or edit scoreboards on this list.
                </p>
                <p>
                    Press "Add Scoreboard" to create a new scoreboard. The
                    scoreboard options are listed below:
                </p>
                <div class="grid">
                    <div class="col-12 md:col-5">
                        <ul>
                            <li>
                                <strong>Name</strong> - Must be alphanumeric and
                                unique, your scoreboard page url will be
                                generated from this name.
                            </li>
                            <li>
                                <strong>Row Order</strong> - If you want your
                                scores to be shown from highest score to lowest
                                or vise vera.
                            </li>
                            <li>
                                <strong>Number of Rows</strong> - How many
                                scores to show on your scoreboard page.
                            </li>
                            <li>
                                <strong>links to Game Stores</strong> - enter a
                                link to your Steam, Play store and App store
                                page.
                            </li>
                            <li>
                                <strong>Description</strong> - details about
                                your game, this information shows on your
                                scoreboard page.
                            </li>
                            <li>
                                <strong>Icon Image</strong> - Upload an icon to
                                represent your game on our website.
                            </li>
                            <li>
                                <strong>Banner Image</strong> - Upload a banner
                                to show on top of your scoreboard page.
                            </li>
                        </ul>
                    </div>
                    <div class="col-12 md:col-7 flex justify-content-center">
                        <p class="code" style="max-width: 582px">
                            <img
                                src="@/assets/example-edit-scoreboard.jpg"
                                style="
                                    width: 100%;
                                    max-width: 582px;
                                    height: auto;
                                "
                            />
                        </p>
                    </div>
                </div>

                <p>
                    Once created, each scoreboard is given a unique private
                    <b>API tagId</b> shown on your dashboard. Keep this
                    information secret as this is the key to updating your
                    scoreboard through the API.
                </p>
                <Divider />
                <h2>Using the API</h2>
                <p>
                    GM Scoreboard uses secure https get requests to set and
                    retreive data. Data is returned in JSON format with a
                    "success" status parameter if the request was completed
                    successfully.
                </p>

                <h2>Set a Score</h2>

                <p>
                    Replace the keywords in the url below with your unqiue
                    tagId, desired player name and score. Player names must be
                    alphanumeric and no longer than 24 characters, scores must
                    be numeric with any commas or decimals removed and can be
                    upto plus or minus 999,999,999.
                </p>

                <p class="code">
                    https://gmscoreboard.com/api/set-score/?tagid=<b>YOUR-TAG-ID</b>&player=<b>PLAYER-NAME</b>&score=<b
                        >YOUR-SCORE</b
                    >
                </p>

                <p>
                    <strong>Submit a score Example: </strong><br />
                    <a
                        href="https://gmscoreboard.com/api/set-score/?tagid=78b57ded260f826682ad5d29ab433ca5&player=DemoPlayerName&score=1337"
                        >https://gmscoreboard.com/api/set-score/?tagid=78b57ded260f826682ad5d29ab433ca5&player=DemoPlayerName&score=1337</a
                    ><br />
                    Click the link to submit a score on our example scoreboard.
                    You can see the url is as described above, you may edit the
                    player name and score to test.
                </p>
                <p>
                    <strong>View example Scoreboard</strong><br />
                    <a href="https://gmscoreboard.com/Example-Scoreboard"
                        >https://gmscoreboard.com/Example-Scoreboard</a
                    ><br />
                    Click this link to view the example scoreboard and see the
                    scores you submit for testing.
                </p>

                <p>
                    You can find your tagId on your dashboard where all your
                    scoreboards are listed. Copy and paste this text into the
                    link above replaceing "YOUR-TAG-ID"
                </p>
                <p class="code">
                    <img
                        src="@/assets/example-dashboard.jpg"
                        style="width: 100%; max-width: 1074px; height: auto"
                    />
                </p>

                <p>
                    The response will have a status, a type which is always
                    "set-score" and a rank of the new high score. Example
                    response below.
                </p>

                <p class="code">
                    {"status":"success","type":"set-score","message":"","rank":1}
                </p>

                <p>
                    A success status is returned if the score was saved into the
                    database but the score will only show on your scoreboard if
                    it falls within the top scores specified in the scoreboard
                    settings.
                </p>

                <p>
                    A duplicate status is returned if the exact player name and
                    score has already been submitted to this scoreboard. error
                    status is returned for all any other reason the set score
                    request failed.
                </p>

                <p>
                    <i
                        >Note: it is your responsibility to filter player names
                        that do not abide by our terms of service before
                        submiting a score.</i
                    >
                </p>

                <Divider />

                <h2>Retreive Scores</h2>

                <p>
                    Replace the keywords in the url below with your unique
                    tagId, and optionally desired number of scores to retreive.
                    If "num" is not specified the maximum amount of scores
                    allowed through the API will be returned. Currently up to
                    the top 5 scores will be returned. All scores are shown on
                    your scoreboard page.
                </p>

                <p class="code">
                    https://gmscoreboard.com/api/get-scores/?tagid=<b>YOUR-TAG-ID</b>&num=<b
                        >NUMBER-OF-SCORES</b
                    >
                </p>

                <p>
                    A success status and array of scores is returned. Each score
                    consists of the player, score and create_timestamp. The data
                    is returned in the ranking order depending on if the
                    scoreboard is set to High or Low order. Example result
                    below.
                </p>

                <pre class="code">{{ scoreDemoData }}</pre>

                <p>
                    An error status is returned if scores could not be
                    retreived. You will need to parse this json response to fit
                    you needs or you can always send players to your scoreboard
                    url on gmscoreboard.com to view the top scores.
                </p>

                <h4>Support</h4>
                <p>
                    Users may contact me at
                    <a href="mailto:gmscoreboard@gmail.com"
                        >gmscoreboard@gmail.com</a
                    >
                    with bug reports and feature requests.
                </p>
                <h4>Known Issues</h4>
                <p>
                    1. GM Scoreboard has issues communicating with the server
                    when playing a Game Maker Studio game using html5 or Opera
                    GX Exports. The simple solution is to replace
                    "https://gmscoreboard.com" in your api calls with
                    "https://gmscoreboard-2021-backend-nodejs-9hpr6.ondigitalocean.app"
                </p>
            </div>

            <div class="col-12 md:col-4"></div>
        </template>
    </Card>
</template>

<script>
export default {
    head: {
        title: {
            inner: "GM Scoreboard Documentation",
        },
        meta: [
            {
                name: "description",
                content:
                    "This page serves as the how to guide for using GM Scoreboard. Please register an account and login to follow along wiht this guide.",
                id: "desc",
            },
        ],
    },
    components: {},
    setup() {
        const scoreDemoData = JSON.stringify(
            {
                scores: [
                    {
                        player: "coolplayername",
                        score: 607,
                        create_timestamp: "2021-09-10T20:21:11.000Z",
                    },
                    {
                        player: "xxradmanxx",
                        score: 257,
                        create_timestamp: "2021-09-10T20:21:05.000Z",
                    },
                    {
                        player: "butterbiscuits",
                        score: 207,
                        create_timestamp: "2021-09-10T20:20:55.000Z",
                    },
                ],
                status: "success",
                type: "get-scores",
                message: "",
            },
            undefined,
            2
        );

        return {
            scoreDemoData,
        };
    },
};
</script>

<style lang="scss" scoped>
#app .p-card-content p a {
    text-decoration: underline;
}
.code {
    background: #e9ecef;
    padding: 1rem 1rem;
    border-radius: 2px;
    overflow-x: auto;
}

.section ul li {
    margin-bottom: 0.5rem;
}
</style>
