<template>
	<Card class="text-center">
		<template #title> 404 </template>
		<template #content> Page not found. </template>
	</Card>
</template>

<script>
export default {
	head:{title:{inner: '404 Not Found'}},
	components: {},
	setup() {
		return {};
	},
};
</script>

<style>
</style>