<template>
	<Card class="">
		<template #content>
			<div class="section col-12 md:col-12">
				<h1>Recent Game Scores</h1>

				<scoreDisplay num="50" />
			</div>
		</template>
	</Card>
</template>

<script>
import scoreDisplay from "@/components/ScoreDisplay";

export default {
	head: {
		title: {
			inner: "Recent Game Scores",
		},
		meta: [
			{
				name: "description",
				content: "Showcase of games who have new high scoring players.",
				id: "desc",
			},
		],
	},
	components: {
		scoreDisplay,
	},
	setup() {
		return {};
	},
};
</script>

<style lang="scss">
</style>
