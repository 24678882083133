<template>
<div class="score-display-con md:m-2 grid">

    <div v-for="item in items" :key="item.id" class="col-12 md:col-4">
        <router-link :to="item.url" class="single-score-con fade" v-if="item.name">
            <div class="img-con flex align-items-center justify-content-center" style="">
                <img class="" :src="item.iconUrl" @error="imgFallback(item)" :alt="item.name" width="100" height="100" />
            </div>
            <div class="details-flex flex align-items-center w-full px-3 py-2">
                <div class="details-con">
                    <span class="name">{{item.name}}</span>
                    <div class="player-score flex align-items-center justify-content-between">
                        <span class="player">{{item.player}}</span>
                        <span class="score">{{item.score}}</span>
                    </div>
                </div>
            </div>
        </router-link>
        <Skeleton width="100%" height="100px" v-else />
    </div>

</div>
</template>

<script>
import {
    ref,
    onMounted,
} from 'vue'
import api from '@/services/ApiService'

export default {
    components: {},
    props: {
        num: String
    },
    setup(props) {

        const items = ref([]);
        for (let i = 0; i < Math.min(21,props.num); i++) {
        items.value.push({
            iconUrl: "",
            id: 0,
            name: "",
            player: "",
            score: 0,
            url: ""
        });
        }

        onMounted(async () => {
            let res = await api.get('/get-score-display/', {
                num: props.num,
            });
            if (res.success) {
                console.log(res.scores);

                res.scores.forEach(function (item) {
                    item.url = "/" + item.viewUrl;

                    if (item.iconUrl.length <= 0) {
                        item.iconUrl = 'default-gm-icon.png';
                    }
                    item.iconUrl = 'https://upload.gmscoreboard.com/uploads/' + item.iconUrl;
                });

                items.value = res.scores;
            }
        });

        const imgFallback = (item) => {
            item.iconUrl = 'https://upload.gmscoreboard.com/uploads/default-gm-icon.png';
        }

        return {
            items,
            imgFallback
        };
    },
};
</script>

<style lang="scss" scoped>
#app {
    a.single-score-con {
        display: flex;
        width: 100%;
        border: 0px solid #000;
        border-radius: .75rem;
        overflow: hidden;
        background: #e9ecef;
        text-decoration: none;

        img {
            border: 1px solid #f0f2f5;
            border-right: 0px;
            width: 100px;
            height: 100px;
        }

        .details-flex {
            border: 1px solid #fff;
            border-left: 0px;
        }

        .details-con {
            width: 100%;
            padding-bottom: .5rem;
            

            .name {
                font-weight: bold;
                font-size: 1.25em;
                line-height: 1.1em;
                min-height: 24px;
                max-height: 46px;
                display: inline-block;
                overflow: hidden;
            }

            .player-score {
                margin-top: 3px;
            }
        }
    }
}
</style>
