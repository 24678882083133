<template>
  <Dialog
    header="Terms and Conditions"
    position="top"
    v-model:visible="displayTerms"
    style="width:100%; max-width:960px"
  >
    <Textarea
      class="terms"
      v-model="terms"
      rows="20"
      style="width:100%"
      readonly
    />
  </Dialog>

  <form
    @submit.prevent="handleSubmit(!v$.$invalid)"
    class="registration-form p-fluid"
  >
    <div class="field">
      <div class="p-float-label">
        <InputText
          id="name"
          v-model="v$.name.$model"
          :class="{ 'p-invalid': v$.name.$invalid && submitted }"
        />
        <label for="name" :class="{ 'p-error': v$.name.$invalid && submitted }"
          >Name*</label
        >
      </div>
      <small
        v-if="(v$.name.$invalid && submitted) || v$.name.$pending.$response"
        class="p-error"
        >{{ v$.name.required.$message.replace("Value", "Name") }}</small
      >
    </div>
    <div class="field">
      <div class="p-float-label p-input-icon-right">
        <i class="pi pi-envelope" />
        <InputText
          id="email"
          v-model="v$.email.$model"
          :class="{ 'p-invalid': v$.email.$invalid && submitted }"
          aria-describedby="email-error"
        />
        <label
          for="email"
          :class="{ 'p-error': v$.email.$invalid && submitted }"
          >Email*</label
        >
      </div>
      <span v-if="v$.email.$error && submitted">
        <span
          id="email-error"
          v-for="(error, index) of v$.email.$errors"
          :key="index"
        >
          <small class="p-error">{{ error.$message }}</small>
        </span>
      </span>
      <small
        v-else-if="
          (v$.email.$invalid && submitted) || v$.email.$pending.$response
        "
        class="p-error"
        >{{ v$.email.required.$message.replace("Value", "Email") }}</small
      >
    </div>
    <div class="field">
      <div class="p-float-label">
        <Password
          id="password"
          v-model="v$.password.$model"
          :class="{ 'p-invalid': v$.password.$invalid && submitted }"
          toggleMask
        >
          <template #header>
            <h5>Pick a password</h5>
          </template>
          <Divider />
          <template #footer="sp">
            {{ sp.level }}
            <p class="p-mt-2">
              Must be atleast 8 charcters long<br />
              with an uppercase letter, <br />a lowercase letter, <br />a
              numeral and special character.
            </p>
          </template>
        </Password>
        <label
          for="password"
          :class="{ 'p-error': v$.password.$invalid && submitted }"
          >Password*</label
        >
      </div>
      <small
        v-if="
          (v$.password.$invalid && submitted) || v$.password.$pending.$response
        "
        class="p-error"
        >{{ v$.password.required.$message.replace("Value", "Password") }}</small
      >
    </div>

    <div class="field">
      <div class="p-float-label">
        <Dropdown
          id="country"
          v-model="v$.country.$model"
          :class="{ 'p-invalid': v$.country.$invalid && submitted }"
          :options="countries"
          optionLabel="name"
          :filter="true"
          :showClear="true"
        />
        <label
          for="country"
          :class="{ 'p-error': v$.country.$invalid && submitted }"
          >Country*</label
        >
      </div>
      <small
        v-if="
          (v$.country.$invalid && submitted) || v$.country.$pending.$response
        "
        class="p-error"
        >{{ v$.country.required.$message.replace("Value", "Country") }}</small
      >
    </div>

    <div class="field field-checkbox">
      <Checkbox
        id="accept"
        name="accept"
        value="Accept"
        v-model="v$.accept.$model"
        :class="{ 'p-invalid': v$.accept.$invalid && submitted }"
      />
      <label
        for="accept"
        :class="{ 'p-error': v$.accept.$invalid && submitted }"
        >I agree to the
        <a href="#" @click="openTerms">terms and conditions</a>*</label
      >
    </div>
    <Button type="submit" class="p-mt-2" :disabled="submitDisabled">
      <div class="text-center" style="width:100%">
        <i
          v-show="submitDisabled"
          class="pi pi-spin pi-spinner"
          style="fontSize: 1rem"
        ></i>
        Register
      </div>
    </Button>
  </form>
</template>

<script>
import { reactive, ref, onMounted } from "vue";

import axios from "axios";
import { email, required, minLength } from "@vuelidate/validators";

import { useVuelidate } from "@vuelidate/core";

import api from "@/services/ApiService";
import router from "@/router";

import { useReCaptcha } from "vue-recaptcha-v3";

import { useGtag } from "vue-gtag-next";

export default {
  setup() {
    const { executeRecaptcha, recaptchaLoaded } = useReCaptcha();

    const state = reactive({
      name: "",
      email: "",
      password: "",
      accept: null,
    });

    const rules = {
      name: {
        required,
      },
      email: {
        required,
        email,
      },
      password: {
        required,
        minLength: minLength(8),
        containsUppercase: function(value) {
          return /[A-Z]/.test(value);
        },
        containsLowercase: function(value) {
          return /[a-z]/.test(value);
        },
        containsNumber: function(value) {
          return /[0-9]/.test(value);
        },
        containsSpecial: function(value) {
          return /[#?!@$%^&*-]/.test(value);
        },
      },
      country: {
        required,
      },
      accept: {
        required,
      },
    };

    //const countryService = ref(new CountryService());
    const terms = ref("");
    const submitted = ref(false);
    const countries = ref([]);
    const country = ref();
    const submitDisabled = ref(false);

    const v$ = useVuelidate(rules, state);

    const { query } = useGtag();

    onMounted(() => {
      axios
        .get("/data/countries.json")
        .then((res) => (countries.value = res.data.countries));
      axios.get("/data/terms.txt").then((res) => (terms.value = res.data));
      console.log("mounted", countries.value);
    });

    const displayTerms = ref(false);
    const openTerms = () => {
      console.log("open terms");
      displayTerms.value = true;
    };

    async function handleSubmit(isFormValid) {
      submitted.value = true;
      if (!isFormValid) {
        return;
      }
      submitDisabled.value = true;

      //get recaptcha token
      await recaptchaLoaded();
      const recaptchaToken = await executeRecaptcha("login");

      //create account request
      let send_data = JSON.parse(JSON.stringify(state));
      send_data.recaptchaToken = recaptchaToken;
      send_data.country = send_data.country.name;
      delete send_data.accept;
      let res = await api.post("create-account", send_data);
      if (res.success) {
        query("event", "user-registration", {
          event_category: "engagement",
          event_label: "user-registration",
          value: 1,
        });
        router.push("login");
      }

      submitDisabled.value = false;
    }

    return {
      state,
      v$,
      handleSubmit,
      submitted,
      countries,
      country,
      terms,
      openTerms,
      displayTerms,
      submitDisabled,
    };
  },
};
</script>

<style lang="scss">
.registration-form {
  max-width: 450px;
  width: 100%;
  margin: 0 auto;

  .field {
    margin-bottom: 1.5rem;
  }

  .field > label {
    margin-bottom: 0.25em;
  }
}
</style>
