<template>
<form @submit.prevent="handleSubmit(!v$.$invalid)" class="registration-form p-fluid">
    <div class="field">
        <div class="p-float-label p-input-icon-right">
            <i class="pi pi-envelope" />
            <InputText id="email" v-model="v$.email.$model" :class="{'p-invalid':v$.email.$invalid && submitted}" aria-describedby="email-error" />
            <label for="email" :class="{'p-error':v$.email.$invalid && submitted}">Email*</label>
        </div>
        <span v-if="v$.email.$error && submitted">
            <span id="email-error" v-for="(error, index) of v$.email.$errors" :key="index">
                <small class="p-error">{{error.$message}}</small>
            </span>
        </span>
        <small v-else-if="(v$.email.$invalid && submitted) || v$.email.$pending.$response" class="p-error">{{v$.email.required.$message.replace('Value', 'Email')}}</small>
    </div>
    <Button type="submit" label="Send Reset Email" class="p-mt-2" />
</form>
</template>

<script>
import {
    reactive,
    ref,
    onMounted
} from 'vue';

import {
    email,
    required
} from "@vuelidate/validators";
import {
    useVuelidate
} from "@vuelidate/core";

import api from "@/services/ApiService";
//import router from "@/router";
import emitter from '@/emitter'

export default {
    setup() {
        const state = reactive({
            email: '',
        });

        const rules = {
            email: {
                required,
                email
            }
        };

        const submitted = ref(false);
        const v$ = useVuelidate(rules, state);

        onMounted(() => {})

        async function handleSubmit(isFormValid) {
            console.log("submit");
            submitted.value = true;

            if (!isFormValid) {
                return;
            }

            let send_data = state;
            let res = await api.post('forgot-password', send_data);
            if (res.success) {
                console.log('success')
                emitter.emit('logout');
            }
        }

        return {
            state,
            v$,
            handleSubmit,
            submitted,
        }
    }
}
</script>

<style lang="scss">
.registration-form {
    max-width: 450px;
    width: 100%;
    margin: 0 auto;

    .field {
        margin-bottom: 1.5rem;
    }

    .field>label {
        margin-bottom: .25em;
    }
}
</style>
