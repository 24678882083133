


import Button from 'primevue/button';
import Card from 'primevue/card';
import Dialog from 'primevue/dialog';
import Password from 'primevue/password';
import InputText from 'primevue/inputtext';
import Dropdown from 'primevue/dropdown';
import Checkbox from 'primevue/checkbox';
import Textarea from 'primevue/textarea';
import Divider from 'primevue/divider';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Slider from 'primevue/slider';
import FileUpload from 'primevue/fileupload';
import Skeleton from 'primevue/skeleton';
import Tooltip from 'primevue/tooltip';

export default function (app) {
    app.component('Button', Button);
    app.component('Card', Card);
    app.component('Dialog', Dialog);
    app.component('Password', Password);
    app.component('InputText', InputText);
    app.component('Dropdown', Dropdown);
    app.component('Textarea', Textarea);
    app.component('Checkbox', Checkbox);
    app.component('Divider', Divider);
    app.component('DataTable', DataTable);
    app.component('Column', Column);
    app.component('Slider', Slider);
    app.component('FileUpload', FileUpload);
    app.component('Skeleton', Skeleton);
    app.component('Tooltip', Tooltip);
    app.directive('tooltip', Tooltip);
}
