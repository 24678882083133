

// components
import Home from '@/views/Home'
import GameScores from '@/views/GameScores'
import Documentation from '@/views/Documentation'
import ChangeLog from '@/views/changelog'
import basicPage from '@/basicPage'
import scorePage from '@/scorePage'
import Registration from '@/views/registration/Registration'
import VerifyEmail from '@/views/registration/VerifyEmail'
import Login from '@/views/login/Login'
import PasswordReset from '@/views/login/PasswordReset'
import Error404 from '@/views/Error404'

import Dashboard from '@/views/account/dashboard/Dashboard'
import EditScoreboard from '@/views/account/dashboard/EditScoreboard'


const basicRoutes = [
    {
        path: "/:catchAll(.*)",
        component: Error404
    },
    {
        name: "Error404",
        path: "/404",
        component: Error404
    },
    {
        name: "home",
        path: '/',
        component: Home,
    },
    {
        name: "Recent Game Scores",
        path: '/game-scores',
        component: GameScores
    },
    {
        name: "News",
        path: '/news',
        component: Home
    },
    {
        name: "Documentation",
        path: '/documentation',
        component: Documentation
    },
    {
        name: "ChangeLog",
        path: '/changelog',
        component: ChangeLog
    },
    {
        name: "Registration",
        path: '/registration',
        component: Registration
    },
    {
        name: "Verify Email",
        path: '/verify-email/:email_token?',
        component: VerifyEmail,
        props: true
    },
    {
        name: "Login",
        path: '/login/:logout?',
        component: Login,
        props: true
    },
    {
        name: "Password Reset",
        path: '/password-reset/:token?',
        component: PasswordReset,
        props: true
    },
    {
        name: "Dashboard",
        path: '/account/dashboard',
        component: Dashboard
    },
    {
        name: "Add Edit Scoreboard",
        path: '/account/edit-scoreboard/:id?',
        component: EditScoreboard,
        props: true
    },
]

const routes = [
    {
        name: "main",
        path: '/',
        component: basicPage,
        children: basicRoutes
    },
    {

        path: '/:scoreboard',
        component: scorePage,
        props: true
    },
    /*{
        path: '/handle_score.php', redirect: to => {
            console.log('handle_score.php', to);
            window.location.href = "https://gmscoreboard.com/" + to.fullPath;
        }

    }
    */


];

import { createWebHistory, createRouter } from "vue-router";
const router = createRouter({
    history: createWebHistory(),
    routes,
});

//only track analytics if production.
import { trackRouter } from "vue-gtag-next";
if (process.env.NODE_ENV == 'production') {
    trackRouter(router, {
        template(to) {
            //const { pageview } = useGtag();
            //to.page_title = to.name;
            //let data = { page_path: to.path, send_page_view: true };
            console.log('pageview', to)
            //pageview(data);
            return {
                page_title: to.name,
                page_path: to.path
            }
        }
    });
}

export default router;


/*
    {
        path: "/:catchAll(.*)",
        component: Error404
    }

    */