<template>
<form @submit.prevent="handleSubmit(!v$.$invalid)" class="registration-form p-fluid">
    <div class="field">
        <div class="p-float-label">
            <Password id="password" v-model="v$.password.$model" :class="{'p-invalid':v$.password.$invalid && submitted}" toggleMask>
                <template #header>
                    <h5>Pick a new password</h5>
                </template>
                <Divider />
                <template #footer="sp">
                    {{sp.level}}
                    <p class="p-mt-2">Must be atleast 8 charcters long<br /> with an uppercase letter, <br />a lowercase letter, <br />a numeral and special character.</p>
                </template>
            </Password>
            <label for="password" :class="{'p-error':v$.password.$invalid && submitted}">New Password*</label>
        </div>
    </div>
    <Button type="submit" label="Submit" class="p-mt-2" />
    <div class="text-muted text-center mt-2 w-full text-600">
        <router-link to="/password-reset/">Forgot Password</router-link>
    </div>
</form>
</template>

<script>
import {
    reactive,
    ref,
    onMounted
} from 'vue';

import {
    minLength,
    required
} from "@vuelidate/validators";
import {
    useVuelidate
} from "@vuelidate/core";

import api from "@/services/ApiService";
//import router from "@/router";
import emitter from '@/emitter'

export default {
    props: {
        token: String
    },
    setup(props) {
        const state = reactive({
            email_token: props.token,
            password: '',
        });

        const rules = {
            password: {
                required,
                minLength: minLength(8),
                containsUppercase: function (value) {
                    return /[A-Z]/.test(value)
                },
                containsLowercase: function (value) {
                    return /[a-z]/.test(value)
                },
                containsNumber: function (value) {
                    return /[0-9]/.test(value)
                },
                containsSpecial: function (value) {
                    return /[#?!@$%^&*-]/.test(value)
                }
            }
        };

        const submitted = ref(false);
        const v$ = useVuelidate(rules, state);

        onMounted(() => {})

        async function handleSubmit(isFormValid) {
            console.log("submit");
            submitted.value = true;

            if (!isFormValid) {
                return;
            }

            let res = await api.post('reset-password', state);
            if (res.success) {
                emitter.emit('logout');
            }

        }

        return {
            state,
            v$,
            handleSubmit,
            submitted,
        }
    }
}
</script>

<style lang="scss">
.registration-form {
    max-width: 450px;
    width: 100%;
    margin: 0 auto;

    .field {
        margin-bottom: 1.5rem;
    }

    .field>label {
        margin-bottom: .25em;
    }
}
</style>
